<template>
    <div style="overflow: hidden;">
        <div class="login">
            <div style="display:flex;flex-direction: column; align-items: center; height: 100%  ;">
                <Row class="header">
                    <div class="description">
                        <!-- 上海麦信打造一流的物联网数据服务解决方案 -->
                            打造一流的物联网数据服务解决方案
                    </div>
                    <div class="text">
                        a professional elevator Internet of things solution company
                    </div>
                </Row>
                <div style="flex: 1; display:flex; align-items: center; justify-content:center;">
                    <Row class="login-form">
                        <!-- <div class="text">欢迎使用上海麦信智慧电梯数据平台V2.1</div> -->
                        <div class="text">欢迎使用金科智慧服务电梯管理系统</div>
                        <!-- <div class="text-2">CubeE Connect V2.1</div> -->
                        <div style="padding:0 83px">
                            <Form ref="usernameLoginForm" :model="form" :rules="rules" @keydown.enter.native="submitLogin">
                                <FormItem prop="userName">
                                    <Input v-model="form.userName" prefix="ios-contact" clearable placeholder="请输入用户名" autocomplete="off" />
                                </FormItem>
                                <FormItem prop="passWord">
                                    <Input type="password" v-model="form.passWord" prefix="ios-lock" clearable placeholder="请输入密码" autocomplete="off" />
                                </FormItem>
                                <FormItem prop="userName" v-if="shoeCode">
                                    <Input v-model="form.verifyCode" prefix="ios-barcode" clearable placeholder="请输入验证码" autocomplete="off" />
                                </FormItem>
                            </Form>
                            <Row>
                                <Button class="login-btn" type="primary" size="large" :loading="loading" @click="submitLogin" long>
                                    <span v-if="!loading">登录</span>
                                    <span v-else>登录中...</span>
                                </Button>
                            </Row>
                        </div>
                    </Row>
                </div>
                <div class="footer">
                    <!-- <div>
                        Copyright © 2022 www.shmashine.com All rights reserved.
                    </div>
                    <div style="display:flex; align-items: center; justify-content: center;">
                        <img style="width:23px; height: 24px; margin-right: 5px;" src="@/assets/gh.png" />
                        <a style="color: #fff;" href="https://beian.miit.gov.cn/" target="_blank">
                            沪ICP备16041439号</a>
                    </div> -->
                </div>
            </div>
        </div>
        <FaultAlarm ref="faultAlarm"/>
    </div>
</template>
<script>
// import { PostToken, login } from '@/api/login/index.js'
import FaultAlarm from '@/components/alarm/index'
export default {
    data() {
        const validateMobile = (rule, value, callback) => {
            var reg = /^[1][3,4,5,7,8][0-9]{9}$/
            if (!reg.test(value)) {
                callback(new Error('手机号格式错误'))
            } else {
                callback()
            }
        }
        return {
            error: false,
            errorMsg: '',
            tabName: 'username',
            saveLogin: true,
            loading: false,
            sending: false,
            sended: false,
            count: 60,
            countButton: '60 s',
            maxLength: 6,
            errorCode: '',
            shoeCode: false,
            form: {
                userName: '',
                passWord: '',
                verifyCode: null
            },
            rules: {
                userName: [{
                    required: true,
                    message: '账号不能为空',
                    trigger: 'blur'
                }],
                passWord: [{
                    required: true,
                    message: '密码不能为空',
                    trigger: 'blur'
                }],
                verifyCode: [{
                    required: true,
                    message: '验证码不能为空',
                    trigger: 'blur'
                }]
            }
        }
    },
    components:{
        FaultAlarm
    },
    methods: {
        submitLogin() {
            this.$refs.faultAlarm.openSwitch()
            this.$refs['usernameLoginForm'].validate(valid => {
                if (valid) {
                    this.loading = true
                    this.$store.dispatch('user/login', this.form).then(() => {
                        // this.$router.replace({ path:'/' })
                      this.$router.push({ path: this.redirect || '/' })
                        this.loading = false
                    }).catch(() => {
                        this.loading = false
                    })
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.test {
    width: 100px;
    height: 900px;
    background-color: pink;
}

.login {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: url('../../assets/login-bg.jpg'); //  #f3faff;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;

        .description {
            font-size: 40px;
            color: #fff;
            margin-bottom: 29px;
            line-height: 40px;
        }

        .text {
            font-size: 24px;
            color: #fff;
            line-height: 24px;
        }

        margin-top: 100px;
    }

    .login-form {
        width: 475px;
        // height: 328px;
        border-radius: 10px;
        //   background-color: #fff;
        background-color: rgba(255, 255, 255, 0.8);
        // padding: 0 83px;
        text-align: center;

        .text {
            color: #38a9e1;
            margin: 40px 0 10px 0;
            font-size: 20px;
            font-weight: 700;
        }

        .text-2 {
            font-size: 14px;
            color: #38a9e1;
            font-weight: 700;
            margin-bottom: 25px;
        }

        .login-btn {
            margin-top: 16px;
            background-color: #38a9e1;
            color: #fff;
            font-size: 20px;
            margin-bottom: 60px;
        }
    }

    .footer {
        color: #fff;
        font-size: 14px;
        margin-bottom: 38px;
        //   transform: -50%;
    }
}

::v-deep .ivu-input {
    background-color: #fff;
    border-radius: 10px;
    height: 40px;
}

::v-deep .ivu-input-prefix,
.ivu-input-suffix {
    display: flex;
    align-items: center;
}

::v-deep .ivu-icon-ios-contact:before,
.ivu-icon-ios-lock:before {
    padding-left: 19px;
}

::v-deep .ivu-input-with-prefix {
    padding-left: 60px;
}

::v-deep .ivu-icon-ios-barcode:before {
    padding-left: 19px;
}

::v-deep input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
    background-color: #fff !important; //设置input框记住密码背景颜色
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
}

::v-deep .ivu-form-item {
    margin-bottom: 16px;
}

::v-deep .ivu-icon-ios-lock:before {
    padding-left: 19px;
}

::v-deep .ivu-input {
    background-color:#fff!important;
    color:#000!important;
}
</style>